import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import $ from 'jquery'

import styles from "./header.module.css"

export default function Header(props) {

     return (
        <header className={styles.header}>
            <Container>
                <Row className={styles.rowLine}>
                    <Col className={styles.headerTitle}>
                        arcieri.io
                    </Col>
                </Row>
                <Row className={`${styles.contentRow} ${styles.rowLine}`}>
                    <Col sm={12}>
                        <div className={styles.name}>
                            Kenneth Arcieri
                        </div>
                        <div><i className={`bi bi-code-slash ${styles.titleIcon}`}/> 
                            &nbsp;<span className={styles.title}>Lead Software Engineer</span>
                        </div>
                        <div><i className={`bi bi-geo-alt ${styles.locationIcon}`}/> 
                        &nbsp;<span className={styles.location}>New York, NY</span>
                        </div>
                        <div><i className={`bi bi-envelope-at-fill ${styles.emailIcon}`}/> 
                            &nbsp;<span className={`${styles.title} ${styles.email}`} data-user="olleh" data-domain="oi.ireicra" title="sorry, you need to type it out because of the bots :("></span>
                        </div>
                        <div>
                            <a className={styles.github} href="https://www.github.com/afuzzyllama" target="_blank" rel="noopener noreferrer">GitHub</a>
                            <a className={styles.linkedin} href="https://www.linkedin.com/in/karcieri/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                            <span className={styles.pdf} onClick={() => window.print()}><i className={`bi bi-file-earmark-pdf-fill ${styles.pdfIcon}`}/></span>
                        </div>
                    </Col>
                </Row>
                <Row className={styles.rowLine}>
                    <Col>
                        <div className={styles.nav}>
                            <a href="#projects">Projects</a> | <a href="#experience">Experience</a> | <a href="#education">Education</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </header>);
}

