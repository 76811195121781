import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import styles from "./educationCard.module.css"

export default function EducationCard(props) {

    const [expand, setExpand] = useState(false);

    var education = props.education;

    return (
        <div className={styles.card}>
            <div className={styles.content}>
                <Container>
                    <Row>
                        <Col xs="auto">
                            <div className={styles.logo}>
                                <img src={`${process.env.PUBLIC_URL}${education.logo}`} alt={education.school} />
                            </div>
                        </Col>
                        <Col>
                            <div className={styles.header}>
                                <Container>
                                    <Row>
                                        <Col xs="12" lg="auto">
                                            <div className={styles.name}>
                                                {education.school}
                                            </div>
                                        </Col>
                                        <Col xs="12" lg="auto">
                                            <span className={styles.location}><i className={`bi bi-geo-alt ${styles.locationIcon}`} /> {education.location}</span>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="auto" className={styles.paddingCol}>
                            <div className={styles.logo}>
                                <img src={`${process.env.PUBLIC_URL}${education.logo}`} alt={education.school} />
                            </div>
                        </Col>
                        <Col>
                            <Container>
                                <Row>
                                    <Col xs="12" lg="auto">
                                        <i className={`bi bi-file-earmark-text ${styles.degreeIcon}`} /> <span className={styles.degree}>{education.degree}</span>
                                    </Col>
                                    <Col xs="12" lg="auto">
                                        <span className={styles.graduated}><i className={`bi bi-calendar-range ${styles.calendarIcon}`} /> {education.graduated}</span>
                                    </Col>
                                </Row>
                            </Container>

                            <div className={styles.highlights}>
                                <ul>
                                    {education.highlights.map(function (skill, index) {
                                        return (
                                            <li key={index}>
                                                {skill}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className={styles.highlights}>
                                Highlighted Coursework:
                                <ul>
                                    {education.courses.map(function (course, index) {
                                        if (course.highlight) {
                                            return (
                                                <li key={index}>
                                                    {course.name}
                                                </li>
                                            );
                                        }

                                        return (null);
                                    })}
                                </ul>
                            </div>
                            <div className={styles.expandContainer}>
                                <div className={`${styles.detail} ${styles.expandContent} ${(expand === true ? styles.expand : "")}`} >
                                    All Coursework:
                                    <ul>
                                        {education.courses.map(function (course, index) {
                                            return (
                                                <li key={index}>
                                                    {course.name}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className={`${styles.arrowContainer} ${(expand === false ? styles.show : styles.hide)}`} onClick={() => setExpand(!expand)}>
                                <i className={`bi bi-arrow-bar-down ${styles.arrow}`} />
                            </div>
                            <div className={`${styles.arrowContainer} ${(expand === true ? styles.show : styles.hide)}`} onClick={() => setExpand(!expand)} >
                                <i className={`bi bi-arrow-bar-up ${styles.arrow}`} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div >
    );
}