import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import ProjectCard from './projectCard'

import styles from './projectList.module.css'

export default function ProjectList(props) {

    if (props.projects === undefined || props.projects.length === 0) {
        return (null);
    }

    return (
        <Container>
            <Row>
                <Col>
                    <div className={styles.header}>
                        Projects:
                    </div>
                </Col>
            </Row>
            <Row>
                {props.projects.map(function (project, index) {
                    return (
                        <Col
                            lg={3}
                            sm={6} 
                            key={project.id}>
                            <ProjectCard
                                project={project} />
                        </Col>

                    );
                })}
            </Row>
        </Container>
    );
}