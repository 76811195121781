import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import styles from './projectCard.module.css'

export default function ProjectCard(props) {

    var project = props.project

    if(project === undefined)
    {
        return (null);
    }

    return (
        <div className={styles.card}>
            <Container>
                <Row>
                    <Col xs="auto" lg={12} className={project.image === undefined ? styles.hide : ""}>
                        <div className={styles.image}>
                            <img src={`${process.env.PUBLIC_URL}${project.image}`}  alt={project.title}/>
                        </div>
                    </Col>
                    <Col xs="auto" lg={12}>
                        <div className={styles.title}>
                                <a href={project.link} target="_blank" rel="noopener noreferrer">{project.title}</a> <i className={`bi bi-box-arrow-up-right ${styles.linkIcon}`} />
                            </div>
                            <div>
                            <div className={styles.skills}>
                                <ul>
                                    {project.skills.map(function(skill, index) {
                                        return(
                                            <li key={index}>
                                                {skill}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} lg={12}>
                        <div className={styles.description}>
                            {project.description}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}