import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import ExperienceCard from './experienceCard'

import styles from './experienceList.module.css'

export default function ExperienceList(props) {

    if (props.experiences === undefined || props.experiences.length === 0) {
        return (null);
    }

    return (
        <Container>
            <Row>
                <Col>
                    <div className={styles.header}>
                        Experience:
                    </div>
                </Col>
            </Row>
            <Row>
                {props.experiences.map(function (experience) {
                    return (
                        <Col lg={6} sm={12} key={experience.id}>
                            <ExperienceCard
                                experience={experience} />
                        </Col>);
                })}
            </Row>
        </Container>
    );
}