import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import styles from "./experienceCard.module.css"

export default function ExperienceCard(props) {

    const [expand, setExpand] = useState(false);

    var experience = props.experience;

    return (
        <div className={styles.card}>
            <div className={styles.content}>
                <Container>
                    <Row>
                        <Col xs="auto">
                            <div className={styles.logo}>
                                <img src={`${process.env.PUBLIC_URL}${experience.logo}`} alt={experience.company} />
                            </div>
                        </Col>
                        <Col>
                            <div className={styles.header}>
                                <Container>
                                    <Row>
                                        <Col xs="12">
                                            <div className={styles.name}>
                                                {experience.company}
                                            </div>
                                        </Col>
                                        <Col xs="12">
                                            <span className={styles.location}>
                                                <i className={`bi bi-geo-alt ${styles.locationIcon}`} /> {experience.location}
                                            </span>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="auto" className={styles.paddingCol}>
                            <div className={styles.logo}>
                                <img src={`${process.env.PUBLIC_URL}${experience.logo}`} alt={experience.company} />
                            </div>
                        </Col>
                        <Col>
                            <div>
                                {experience.titles.map(function (title, index) {

                                    var startDate = new Date(title.startDate);
                                    var endDate = title.endDate !== null ? new Date(title.endDate) : null;

                                    var options = { year: "numeric", month: "short" }
                                    var startDateDisplay = startDate.toLocaleString("en-US", options);
                                    var endDateDisplay = "Present";

                                    if (endDate != null) {
                                        endDateDisplay = endDate.toLocaleString("en-US", options);
                                    }
                                    else {
                                        endDate = new Date();
                                    }

                                    var days = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));

                                    const daysInYear = 0.0027379;
                                    var years = days * daysInYear;


                                    const daysInMonth = 0.03287667629;
                                    var months = (days - Math.round(years) / daysInYear) * daysInMonth;

                                    var yearsDisplay = Math.round(years);
                                    yearsDisplay = yearsDisplay > 0 ? `${yearsDisplay} yr${yearsDisplay > 1 ? "s" : ""}` : "";

                                    var monthsDisplay = Math.round(months);
                                    monthsDisplay = monthsDisplay > 0 ? `${monthsDisplay} mo${monthsDisplay > 1 ? "s" : ""}` : "";

                                    var timespanDisplay = `${yearsDisplay} ${monthsDisplay}`.trim();

                                    return (
                                        <Container key={title.id}>
                                            <Row>
                                                <Col sm="4" lg="6">
                                                    <i className={`bi bi-code-slash ${styles.titleIcon}`} /> <span className={styles.title}>{title.name}</span>
                                                </Col>
                                                <Col sm="auto" lg="6">
                                                    <span className={styles.duration}><i className={`bi bi-calendar-range ${styles.calendarIcon}`} /> {startDateDisplay} - {endDateDisplay} ({timespanDisplay})</span>
                                                </Col>
                                            </Row>
                                        </Container>
                                    );
                                })}
                            </div>
                            <div className={styles.appliedSkills}>
                                <ul>
                                    {experience.skills.map(function (skill, index) {
                                        return (
                                            <li key={index}>
                                                {skill}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>

                            <div className={styles.highlight}>
                                {experience.highlight}
                            </div>

                            <div className={styles.expandContainer}>
                                <div className={`${styles.detail} ${styles.expandContent} ${(expand === true ? styles.expand : "")}`} >
                                    <ul>
                                        {experience.detail.map(function (item, index) {

                                            if (item.linkText !== undefined) {
                                                return (
                                                    <li key={index}>
                                                        <a href={item.link} target="_blank" rel="noopener noreferrer">{item.linkText}</a> - {item.text}
                                                    </li>
                                                );
                                            }

                                            return (
                                                <li key={index}>
                                                    {item.text}
                                                </li>
                                            );
                                        })}
                                    </ul>

                                </div>
                            </div>
                            <div className={`${styles.arrowContainer} ${(expand === false ? styles.show : styles.hide)}`} onClick={() => setExpand(!expand)}>
                                <i className={`bi bi-arrow-bar-down ${styles.arrow}`} />
                            </div>
                            <div className={`${styles.arrowContainer} ${(expand === true ? styles.show : styles.hide)}`} onClick={() => setExpand(!expand)}>
                                <i className={`bi bi-arrow-bar-up ${styles.arrow}`} />
                            </div> 
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}