import React, {useEffect, useState} from 'react'
import Header from './components/header/header'
import ExperienceList from './components/experience/experienceList'
import EducationList from './components/education/educationList'
import ProjectList from './components/projects/projectList'

import './colors.css';
import './App.css';

function App() {
  const [summary, setSummary] = useState("");
  const [experiences, setExperiences] = useState([]);
  const [education, setEducation] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/source/experience/summary.json")
      .then(res => res.json())
      .then(
        (result) => {
          setSummary(result.data);
        },
        (error) => {
        }
      )
  }, [])

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/source/experience/experiences.json")
      .then(res => res.json())
      .then(
        (result) => {
          setExperiences(result.data);
        },
        (error) => {
        }
      )
  }, [])

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/source/experience/education.json")
      .then(res => res.json())
      .then(
        (result) => {
          setEducation(result.data);
        },
        (error) => {
        }
      )
  }, [])

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/source/experience/projects.json")
      .then(res => res.json())
      .then(
        (result) => {
          setProjects(result.data);
        },
        (error) => {
        }
      )
  }, [])

  return (
    <div>
      <Header 
        summary={summary} />
      <section id="projects">
        <ProjectList
          projects={projects} />
      </section>
      <section id="experience">
        <ExperienceList
          experiences={experiences} />
      </section>
      <section id="education">
        <EducationList
          education={education} />
      </section>
    </div>
  );
}

export default App;
