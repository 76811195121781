import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import EducationCard from './educationCard'

import styles from './educationList.module.css'

export default function EducationList(props) {

    if(props.education === undefined || props.education.length === 0)
    {
        return (null);
    }

    return (
        <Container>
            <Row>
                <Col>
                    <div className={styles.header}>
                        Education:
                    </div>
                </Col>
            </Row>
            <Row>
                {props.education.map(function (education, index) {
                    return (
                        <Col xs={12} sm={6} key={education.id}>
                            <EducationCard
                                education={education} />
                        </Col>
                    );
                })}
            </Row>
        </Container>
    );
}